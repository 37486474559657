import axios from 'axios';
import Component from 'vue-class-component';
import { Vue, Inject } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
@Component({
  watch: {
    $route() {
      this.$root.$emit('bv::hide::modal', 'login-page');
    },
  },
})
export default class LoginForm extends Vue {
  @Inject('accountService')
  private accountService: () => AccountService;
  public authenticationError = null;
  public login = null;
  public password = null;
  public rememberMe: boolean = null;

  public doLogin(): void {
    const data = {
    "authLogin": {
        "authReference": {
            "environmentId": "6299cba590881d3851d0668c",
            "subscriptionId": ""
        },
        "authModal": {
            "type": "login",
            "mode": ""
        },
        "authLogin": {
            "username": this.login,
            "password": this.password
        },
        "authCallback": {
            "url": "https://auth.trusthub.cloud/api/rest/v7.1.0/core/auth",
            "userId": this.login,
            "password": this.password,
            "auth": "login",
            "retries": "3"
        },
        "authNotify": {
            "email": "dka.narola@gmail.com",
            "sms": "",
            "push": ""
        }
    }
}; 
//{ username: this.login, password: this.password, rememberMe: this.rememberMe };
    axios
      .post('api/authenticate', data)
      .then(result => {
        const bearerToken = result.headers.authorization;
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);
          if (this.rememberMe) {
            localStorage.setItem('jhi-authenticationToken', jwt);
            sessionStorage.removeItem('jhi-authenticationToken');
          } else {
            sessionStorage.setItem('jhi-authenticationToken', jwt);
            localStorage.removeItem('jhi-authenticationToken');
          }
        }
        this.authenticationError = false;
        this.$root.$emit('bv::hide::modal', 'login-page');
        this.accountService().retrieveAccount();
      })
      .catch(() => {
        this.authenticationError = true;
      });
  }
}
